import {Component, OnInit} from '@angular/core';
import {DynamicDialogConfig, DynamicDialogRef} from 'primeng/dynamicdialog';

@Component({
	selector: 'forecast-feedback-popup',
	templateUrl: './forecast-feedback-popup.component.html',
	styleUrls: ['./forecast-feedback-popup.component.scss']
})
export class ForecastFeedbackPopupComponent implements OnInit {

	constructor(public ref: DynamicDialogRef,
				public config: DynamicDialogConfig) {
	}

	ngOnInit(): void {
	}

	onCancel(): void {
		this.ref.close(false);
	}

	onApprove(): void {
		this.ref.close(true);
	}
}
