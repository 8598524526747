<div class="popup-content">
	<p class="message">
		{{ 'wbdo.wholesale.forecastFeedbackDialog.forecastFeedbackBody' | translate }}
	</p>
</div>
<div class="popup-actions">
	<button class="cancel-btn"
			(click)="onCancel()"> {{ 'wbdo.wholesale.forecastFeedbackDialog.forecastFeedbackCancel' | translate }}
	</button>
	<button class="approve-btn"
			(click)="onApprove()"> {{ 'wbdo.wholesale.forecastFeedbackDialog.forecastFeedbackApprove' | translate }}
	</button>
</div>
