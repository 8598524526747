import {BrowserModule} from '@angular/platform-browser';
import {APP_INITIALIZER, NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {AppComponent} from './app.component';
import {ButtonModule} from 'primeng/button';
import {MenubarModule} from 'primeng/menubar';
import {PanelMenuModule} from 'primeng/panelmenu';
import {SidebarModule} from 'primeng/sidebar';
import {AppRoutingModule} from './app-routing.module';
import {ToastModule} from 'primeng/toast';
import {HomeComponent} from './components/home/home.component';
import {FooterComponent} from './components/footer/footer.component';
import {HeaderComponent} from './components/header/header.component';
import {HttpErrorHandlerService, NgxFrfUtilitiesModule} from '@wame/ngx-frf-utilities';
import {HttpRequestInterceptor, NgxAdfsModule, UserIdService} from '@wame/ngx-adfs';
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from '@angular/common/http';
import {environment} from '../environments/environment';
import {TabViewModule} from 'primeng/tabview';
import {RadioButtonModule} from 'primeng/radiobutton';
import {SplitterModule} from 'primeng/splitter';
import {MessageListComponent} from './components/message-list/message-list.component';
import {TableModule} from 'primeng/table';
import {EventCalendarComponent} from './components/event-calendar/event-calendar.component';
import {FullCalendarModule} from '@fullcalendar/angular';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {EventListComponent} from './components/event-list/event-list.component';
import {ScrollPanelModule} from 'primeng/scrollpanel';
import {InputSwitchModule} from 'primeng/inputswitch';
import {UserInfoComponent} from './components/user-info/user-info.component';
import {WholesaleComponent} from './components/wholesale/wholesale.component';
import {SimsPreferencesComponent} from './components/sims-preferences/sims-preferences.component';
import {DropdownModule} from 'primeng/dropdown';
import {MenuModule} from 'primeng/menu';
import {InventoryMetricsComponent} from './components/inventory-metrics/inventory-metrics.component';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {LocaleComponent} from './components/locale/locale.component';
import {DialogModule} from 'primeng/dialog';
import {DialogService} from 'primeng/dynamicdialog';
import {AuthInterceptor} from './components/interceptor/auth-interceptor';
import {InitService} from './service/init-service';
import {SalescodeComponent} from './components/salescode-component/salescode-component';
import {ListboxModule} from 'primeng/listbox';
import {ToolbarModule} from 'primeng/toolbar';
import {SelectButtonModule} from 'primeng/selectbutton';
import {OverlayPanelModule} from 'primeng/overlaypanel';
import {SplitButtonModule} from 'primeng/splitbutton';
import {ControlValidationComponent} from './components/control-validation/control-validation.component';
import {ProgressBarModule} from 'primeng/progressbar';
import {MessageModule} from 'primeng/message';
import {MessagesModule} from 'primeng/messages';
import {MessageService} from 'primeng/api';
import {LocaleService} from './service/locale-service';
import {UserInfoService} from './service/user-info-service';

import {
	ControlValidationErrorsComponent
} from './components/control-validation-errors/control-validation-errors.component';
import {WholesaleBannerComponent} from './components/wholesale-banner/wholesale-banner.component';
import {WholesaleSaveApproveComponent} from './components/wholesale-save-approve/wholesale-save-approve.component';
import {UnsavedChangesComponent} from './components/unsaved-changes/unsaved-changes.component';
import {ToggleAllComponent} from './components/toggle-all/toggle-all.component';
import {CheckboxModule} from 'primeng/checkbox';
import {SimsResetDefaultComponent} from './components/sims-reset-default/sims-reset-default.component';
import {InputNumberModule} from 'primeng/inputnumber';
import {SimsPreferencesW2Component} from './components/sims-preferences-w2/sims-preferences-w2.component';
import {SimsPreferencesW3Component} from './components/sims-preferences-w3/sims-preferences-w3.component';
import {KeyFilterModule} from 'primeng/keyfilter';
import {ForecastFeedbackPopupComponent} from './components/forecast-feedback-popup/forecast-feedback-popup.component';

// AoT requires an exported function for factories
export function HttpLoaderFactory(httpClient: HttpClient) {
	return new TranslateHttpLoader(httpClient,
		'./assets/i18n/',
		'.json');
}

/**
 * Root app module class. Includes top-level NgModule configuration.
 */
@NgModule({
	declarations: [
		AppComponent,
		HeaderComponent,
		HomeComponent,
		MessageListComponent,
		EventCalendarComponent,
		EventListComponent,
		FooterComponent,
		HeaderComponent,
		UserInfoComponent,
		WholesaleComponent,
		InventoryMetricsComponent,
		SalescodeComponent,
		ControlValidationComponent,
		LocaleComponent,
  		ControlValidationErrorsComponent,
    	WholesaleBannerComponent,
		WholesaleSaveApproveComponent,
		SimsPreferencesComponent,
  		UnsavedChangesComponent,
    	ToggleAllComponent,
    	SimsResetDefaultComponent,
		SimsPreferencesW2Component,
		SimsPreferencesW3Component,
		ForecastFeedbackPopupComponent
	],
	imports: [
		BrowserModule,
		BrowserAnimationsModule,
		RouterModule,
		AppRoutingModule,
		ButtonModule,
		MenubarModule,
		PanelMenuModule,
		SidebarModule,
		ToastModule,
		NgxFrfUtilitiesModule,
		NgxAdfsModule.forRoot({
			openidUrl: environment.openidUrl,
			openidClientId: environment.openidClientId,
			openidResource: environment.openidResource
		}),
		TabViewModule,
		RadioButtonModule,
		SplitterModule,
		TableModule,
		FullCalendarModule,
		FormsModule,
		HttpClientModule,
		ScrollPanelModule,
		InputSwitchModule,
		DropdownModule,
		MenuModule,
		DialogModule,
		ListboxModule,
		ToolbarModule,
		SelectButtonModule,
		OverlayPanelModule,
		SplitButtonModule,
		ProgressBarModule,
		MessagesModule,
		MessageModule,
		ToastModule,
		ListboxModule,
		TranslateModule.forRoot({
			loader: {
				provide: TranslateLoader,
				useFactory: HttpLoaderFactory,
				deps: [HttpClient]
			}
		}),
		ReactiveFormsModule,
		CheckboxModule,
		InputNumberModule,
		KeyFilterModule
	],
	providers: [
		MessageService,
		UserIdService,
		LocaleService,
		UserInfoService,
		{
			provide: APP_INITIALIZER,
			useFactory: initApp,
			multi: true,
			deps: [InitService]
		},
		{
			provide: HTTP_INTERCEPTORS,
			useClass: HttpRequestInterceptor,
			multi: true
		},
		{
			provide: HTTP_INTERCEPTORS,
			useClass: AuthInterceptor,
			multi: true
		},
		HttpErrorHandlerService,
		DialogService
	],
	bootstrap: [AppComponent]
})
export class AppModule {
}

/**
 * Initializer for the Authorization service, included in the top-level NgModule config.
 * @param authorizationService Injected InitService object
 * @returns init function of the passed in InitService
 */
export function initApp(authorizationService: InitService): () => void {
	return () => authorizationService.init();
}
